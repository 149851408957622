import { createRouter, createWebHashHistory } from 'vue-router';
import EditViewVue from '@/views/EditView.vue';
import LoginViewVue from '@/views/LoginView.vue';
import ManagerViewVue from '@/views/ManagerView.vue';
import TeamManagerVue from '@/components/TeamManager.vue';
import DashboardViewVue from '@/views/DashboardView.vue';
import PlayerManagerVue from '@/components/PlayerManager.vue';
import TeamCreateVue from '@/components/TeamCreate.vue';
import CommissionerViewVue from '@/views/CommissionerView.vue';
import TeamProfileVue from '@/components/manager/TeamProfile.vue';
import LeagueProfileVue from '@/components/league/LeagueProfile.vue';
import LeagueViewVue from '@/views/LeagueView.vue';
import AdminView from '@/views/AdminView.vue';
import ProfileView from '@/views/ProfileView.vue';
import UserListVue from '@/components/admin/UserList.vue';
import UserEditorVue from '@/components/admin/UserEditor.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: { name: 'Dashboard' },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardViewVue,
    meta: { requiresAuth: true },
  },
  {
    path: '/manager',
    name: 'Manager',
    component: ManagerViewVue,
    meta: { requiresAuth: true },
    children: [
      {
        path: 'team',
        component: TeamProfileVue,
      },
      {
        path: 'players',
        component: PlayerManagerVue,
      },
    ],
  },
  {
    path: '/commissioner',
    name: 'Comissioner',
    component: CommissionerViewVue,
    meta: { requiresAuth: true },
    children: [
      {
        path: 'league',
        component: LeagueProfileVue,
      },
      {
        path: 'teams',
        component: TeamManagerVue,
      },
      {
        path: 'teams/new',
        component: TeamCreateVue,
      },
      {
        path: 'teams/:id/edit',
        name: 'TeamEdit',
        component: EditViewVue,
      },
      {
        path: 'players',
        component: PlayerManagerVue,
      },
    ],
  },
  {
    path: '/league',
    name: 'League',
    component: LeagueViewVue,
    meta: { requiresAuth: true },
  },
  {
    path: '/admin',
    name: 'Admin',
    component: AdminView,
    meta: { requiresAuth: true },
    children: [
      {
        path: 'users/:id/edit',
        component: UserEditorVue,
      },
      {
        path: 'users',
        component: UserListVue,
      },
    ],
  },
  {
    path: '/profile',
    name: 'Profile',
    component: ProfileView,
    meta: { requiresAuth: true },
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginViewVue,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
