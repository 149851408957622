<template>
  <v-card>
    <v-card-header>
      <v-card-title> Team Manager </v-card-title>
    </v-card-header>
    <v-card-text>
      <v-table class="table m-0">
        <caption>
          Teams List
        </caption>
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">League</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="{ id, name, league } in teams" :key="id">
            <td>{{ name }}</td>
            <td>{{ league }}</td>
            <td>
              <v-btn :to="`/manager/teams/${id}/edit`" class="btn btn-primary btn-sm me-2"
                >Edit</v-btn
              >
              <v-btn class="btn btn-danger btn-sm me-2" @click="deleteTeam(id)">Delete</v-btn>
            </td>
          </tr>
        </tbody>
      </v-table>
    </v-card-text>
    <v-card-actions>
      <v-row>
        <v-col>
          <v-btn color="success" to="/commissioner/teams/new">New Team</v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { teamsDb } from '@/db';

export default {
  setup() {
    const teams = teamsDb.useLoadTeams();
    return {
      teams,
      deleteTeam: teamsDb.deleteTeam,
    };
  },
};
</script>
