<template>
  <v-card>
    <v-card-header>
      <v-card-title> User Manager </v-card-title>
    </v-card-header>
    <v-card-text>
      <v-table class="table m-0">
        <caption>
          User List
        </caption>
        <thead>
          <tr>
            <th scope="col">Email</th>
            <th scope="col">First Name</th>
            <th scope="col">Last Name</th>
            <th scope="col">Roles</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="{ id, email, firstName, lastName, roles } in users" :key="id">
            <td>{{ email }}</td>
            <td>{{ firstName }}</td>
            <td>{{ lastName }}</td>
            <td>
              <v-chip
                v-for="role in roles"
                :key="role"
                v-text="role"
                :color="getRoleColor(role)"
                :text-color="'white'"
              ></v-chip>
            </td>
            <td>
              <v-btn :to="`/admin/users/${id}/edit`" class="btn btn-primary btn-sm me-2"
                >Edit</v-btn
              >
              <v-btn class="btn btn-danger btn-sm me-2" @click="deleteTeam(id)">Delete</v-btn>
            </td>
          </tr>
        </tbody>
      </v-table>
    </v-card-text>
    <v-card-actions>
      <v-row>
        <v-col>
          <v-btn color="success" to="/commissioner/teams/new">Invite User</v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  setup() {},
  mounted() {
    this.fetchUsers();
  },
  methods: {
    ...mapActions(['fetchUsers']),
    getRoleColor(role) {
      switch (role) {
        case 'ADMIN':
          return 'primary';
        case 'MANAGER':
          return 'secondary';
        case 'SPECTATOR':
          return 'success';
        default:
          return 'primary';
      }
    },
  },
  computed: {
    ...mapGetters(['users']),
  },
};
</script>
