<template>
  <v-row align="center" justify="center">
    <v-col></v-col>
    <v-col>
      <v-card>
        <v-img src="../assets/logo.svg" height="200px"></v-img>
        <v-row align="center" justify="center">
          <v-col></v-col>
          <v-col align="center" justify="center">
            <v-card-title> Welcome to ScoreIt. </v-card-title>
            <v-card-subtitle> Please login to continue. </v-card-subtitle>
          </v-col>
          <v-col></v-col>
        </v-row>

        <v-card-text>
          <v-container>
            <v-row align="center" justify="center">
              <v-col cols="3" align="center" justify="center">
                <div
                  id="appleid-signin"
                  class="signin-button"
                  data-color="black"
                  data-border="true"
                  data-type="sign in"
                ></div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col></v-col>
  </v-row>
</template>

<script>
import {
  getAuth,
  signInWithEmailAndPassword,
  OAuthProvider,
  signInWithPopup,
  setPersistence,
  browserLocalPersistence,
} from 'firebase/auth';
import { reactive } from 'vue';
import { useRouter } from 'vue-router';

export default {
  mounted() {
    // eslint-disable-next-line no-undef
    AppleID.auth.init({
      clientId: '[CLIENT_ID]',
      scope: '[SCOPES]',
      redirectURI: '[REDIRECT_URI]',
      state: '[STATE]',
      nonce: '[NONCE]',
      usePopup: true, // or false defaults to false
    });

    const oldElement = document.getElementById('appleid-signin');
    const newElement = oldElement.cloneNode(true);
    oldElement.parentNode.replaceChild(newElement, oldElement);
    newElement.addEventListener('click', this.signInWithApple);
  },
  setup() {
    const errMsg = '';
    const auth = getAuth();
    const router = useRouter();
    const form = reactive({ email: '', password: '' });

    document.removeEventListener('click', this);

    const signInWithApple = async () => {
      const provider = new OAuthProvider('apple.com');
      provider.addScope('email');
      provider.addScope('name');

      setPersistence(auth, browserLocalPersistence)
        .then(
          () =>
            // eslint-disable-next-line implicit-arrow-linebreak
            signInWithPopup(auth, provider)
              .then((result) => {
                // The signed-in user info.
                const { user } = result;

                console.log('User', user);

                // Apple credential
                const credential = OAuthProvider.credentialFromResult(result);
                const { accessToken } = credential;
                const { idToken } = credential;
                console.log('accessToken', accessToken);
                console.log('idToken', idToken);

                router.push('/');

                // ...
              })
              .catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log('Error', errorCode, errorMessage);
                // The email of the user's account used.
                const { email } = error;
                console.log('Error email', email);
                // The credential that was used.
                const credential = OAuthProvider.credentialFromError(error);
                console.log(credential);

                // ...
              }),
          // eslint-disable-next-line function-paren-newline
        )
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode, errorMessage);
        });
    };

    const signIn = async () => {
      console.log(form.email, form.password);
      signInWithEmailAndPassword(auth, form.email, form.password)
        .then((userCredential) => {
          // Signed in
          const { user } = userCredential;
          console.log(user);
          // ...
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(`${errorCode}: ${errorMessage}`);
        });
    };

    return {
      form,
      signIn,
      errMsg,
      signInWithApple,
    };
  },
};
</script>

<style>
.signin-button {
  width: 210px;
  height: 40px;
}
</style>
