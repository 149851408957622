import { createApp } from 'vue';
import { getAuth, onAuthStateChanged, connectAuthEmulator } from 'firebase/auth';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';

loadFonts();
let init = true;

const auth = getAuth();

if (process.env.VUE_APP_FIREBASE_LOCAL) {
  console.log('Using local simulator auth');
  connectAuthEmulator(auth, process.env.VUE_APP_FIREBASE_LOCAL_AUTH_URL);
}

onAuthStateChanged(auth, (user) => {
  if (user) {
    user.getIdTokenResult()
      .then((idTokenResult) => {
        console.log(idTokenResult);
        store.dispatch('loggedIn', { ...user, roles: idTokenResult.claims.roles, id: idTokenResult.claims.sub });
        store.dispatch('fetchUser', idTokenResult.claims.sub);
      }).catch((err) => {
        console.log(err);
      });
  }
  store.dispatch('loggedIn', user);
  if (init) {
    init = false;

    router.beforeEach((to, from, next) => {
      console.log('check auth');
      if (to.matched.some((record) => record.meta.requiresAuth)) {
        console.log('requires auth');
        if (!store.state.user.loggedIn) {
          return next({
            path: '/login',
          });
        }
      }
      return next();
    });

    createApp(App)
      .use(router)
      .use(store)
      .use(vuetify)
      .mount('#app');
  }
});
