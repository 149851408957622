import { usersDb } from '@/db';

export default {
  state: () => ({
    users: [],
    selectedUser: {},
  }),
  mutations: {
    SET_USERS: (state, users) => {
      state.users = users;
    },
    SET_USER: (state, id, newUser) => {
      state.users = state.users.map((user) => (user.id === id ? newUser : user));
    },
  },
  actions: {
    fetchUsers: ({ commit }) => {
      usersDb.getUsers().then((users) => {
        commit('SET_USERS', users);
      }).catch((error) => {
        console.log(`Error getting users: ${error}`);
      });
    },
    saveUser: ({ commit }, id, user) => {
      usersDb.updateUser(id, user).then((updatedUser) => {
        commit('SET_USER', id, updatedUser);
      }).catch((error) => {
        console.log(`Error getting users: ${error}`);
      });
    },
  },
  getters: {
    users: (state) => state.users,
    user: (state) => (id) => state.users.find((user) => user.id === id),
  },
};
