<template>
  <v-row>
    <v-col cols="4">
      <AdminSideBar />
    </v-col>
    <v-col>
      <router-view></router-view>
    </v-col>
  </v-row>
</template>

<script>
import AdminSideBar from '@/components/admin/AdminSideBar.vue';

export default {
  name: 'AdminView',
  components: { AdminSideBar },
};
</script>

<style scoped></style>
