import { teamsDb } from '@/db';

export default {
  state: () => ({
    teams: [],
  }),
  mutations: {
    SET_TEAMS: (state, teams) => {
      state.teams = teams;
    },
  },
  actions: {
    fetchTeams: ({ commit }) => {
      teamsDb.getTeams().then((teams) => {
        commit('SET_TEAMS', teams);
      }).catch((error) => {
        console.log(`Error getting teams: ${error}`);
      });
    },
    createTeam: async (vuex, team) => {
      console.log('creating team', team);
      teamsDb.createTeam(team).then((data) => {
        console.log(data);
      }).catch((error) => { console.log(error); });
    },
  },
  getters: {
    getTeams: (state) => state.teams,
  },
};
