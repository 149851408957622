<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-title>{{ user }}</v-card-title>
        <v-card-text>
          <v-form @submit.prevent="onSubmit">
            <div class="form-groupp mt-3">
              <v-text-field
                id="name"
                label="Name"
                placeholder="Name"
                v-model="form.name"
              ></v-text-field>
            </div>
            <div class="form-group mt-3">
              <v-text-field
                id="league"
                label="League"
                placeholder="League"
                v-model="form.email"
              ></v-text-field>
            </div>
            <v-card-actions>
              <v-btn color="success" type="submit">Edit Team</v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col> </v-col>
  </v-row>
</template>

<script>
import { computed, onMounted, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    const userId = computed(() => route.params.id);
    const store = useStore();

    const user = computed(() => store.getters.user(userId.value));
    const form = reactive({ name: '', email: '', roles: [] });

    onMounted(() => {
      store.dispatch('fetchUsers').then((something) => {
        console.log('something', something);
        console.log('user', user.value);
        form.name = user.value.firstName;
        form.email = user.value.email;
        form.roles = user.value.roles;
      });
    });

    const onSubmit = async () => {
      store.dispatch('saveUser', userId, form);
      router.push('/manager/teams');
    };

    return { form, onSubmit, user };
  },
};
</script>
