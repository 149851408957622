import { ref } from '@vue/reactivity';
import { onUnmounted } from '@vue/runtime-core';

import {
  collection, getDocs, doc, getDoc, onSnapshot, addDoc, deleteDoc, setDoc,
} from 'firebase/firestore';

export const getLeagues = async (db) => {
  const teams = await getDocs(collection(db, 'leagues'));
  return teams.docs;
};

export const getLeague = async (id, db) => getDoc(doc(db, 'leagues', id));

export const createLeague = async (team, db) => {
  console.log('Creating Team', team);
  try {
    await addDoc(collection(db, 'leagues'), team);
  } catch (err) {
    console.log(err);
  }
};

export const updateLeague = async (id, team, db) => {
  console.log('Creating Team', team);
  try {
    await setDoc(doc(db, 'leagues', id), team);
  } catch (err) {
    console.log(err);
  }
};

export const deleteLeague = async (id, db) => {
  deleteDoc(doc(db, 'leagues', id));
};

export const useLoadLeague = (db) => {
  const teams = ref([]);
  const close = onSnapshot(collection(db, 'leagues'), (snapshot) => {
    teams.value = snapshot.docs.map((team) => ({ id: team.id, ...team.data() }));
  });
  onUnmounted(close);
  return teams;
};

export const leaguesFirebaseDb = (db) => ({
  getLeagues: () => getLeagues(db),
  getLeague: (id) => getLeague(id, db),
  createLeague: (team) => createLeague(team, db),
  updateLeague: (id, team) => updateLeague(id, team, db),
  deleteLeague: (id) => deleteLeague(id, db),
  useLoadLeague: () => useLoadLeague(db),
});
export default {
  leaguesFirebaseDb,
};
