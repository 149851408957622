import { createStore } from 'vuex';
import { usersDb } from '@/db';
import teams from './modules/teams';
import users from './modules/users';

export default createStore({
  state: {
    user: {
      loggedIn: false,
      email: '',
    },
  },
  getters: {
    myUser: (state) => state.user,
    isInRole: (state) => (role) => {
      console.log(`CHECKING FOR ROLE: ${role}`);

      const hasRole = state.user && state.user.roles && state.user.roles.length
        ? state.user.roles.includes(role)
        : false;

      console.log('HAS ROLE', hasRole);

      return hasRole;
    },
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_USER(state, data) {
      console.log(data);

      const {
        email, displayName, roles, id, firstName,
      } = data;
      state.user = {
        ...state.user, email, displayName, roles, id, firstName,
      };
    },
  },
  actions: {
    loggedIn({ commit }, user) {
      commit('SET_LOGGED_IN', user !== null);
      if (user) {
        commit('SET_USER', user);
      }
    },
    setUser({ commit }, user) {
      if (user) {
        commit('SET_USER', user);
      }
    },
    fetchUser({ commit }, id) {
      usersDb.getUser(id).then((user) => {
        if (user.exists) {
          console.log(user.data());
          if (user) {
            commit('SET_USER', user.data());
          }
        }
      });
    },
  },
  modules: {
    teams,
    users,
  },
});
