<template>
  <TeamEdit />
</template>

<script>
import TeamEdit from '../components/TeamEdit.vue';

export default {
  components: {
    TeamEdit,
  },
};
</script>
