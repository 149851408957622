<template>
  <v-row>
    <v-col cols="4">
      <ProfileSidebar />
    </v-col>
    <v-col></v-col>
  </v-row>
</template>

<script>
import ProfileSidebar from '@/components/profile/ProfileSidebar.vue';

export default {
  name: 'ProfileView',
  components: { ProfileSidebar },
};
</script>

<style scoped></style>
