<template>
  <v-row>
    <v-col cols="3">
      <v-card class="mx-auto" tile>
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <v-avatar color="indigo">
                <v-icon light> mdi-account-circle </v-icon>
              </v-avatar>
            </v-list-item-avatar>
          </v-list-item>
          <v-list-item link>
            <v-list-item-content>
              <v-list-item-title class="text-h6"> John Leider </v-list-item-title>
              <v-list-item-subtitle>{{ myUser.email }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-icon>mdi-menu-down</v-icon>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list color="transparent">
          <v-list-item v-for="item in list" :key="item.name" link :to="item.link">
            <v-list-item-content>
              <v-list-item-title>{{ item.name }} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-col>
    <v-col>
      <router-view></router-view>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  setup() {
    return {
      list: [
        { name: 'League', link: '/commissioner/league' },

        {
          name: 'Teams',
          link: '/commissioner/teams',
        },
        {
          name: 'Players',
          link: '/commissioner/players',
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['myUser']),
  },
};
</script>

<style></style>
