<template>
  <v-row>
    <v-col>Dashboard</v-col>
  </v-row>
</template>

<script>
export default {
  name: 'DashboardView',

  components: {},
};
</script>
