import {
  doc, getDoc, getDocs, collection, setDoc,
} from 'firebase/firestore';

export const getUsers = async (db) => {
  const users = await getDocs(collection(db, 'users'));

  return users.docs.map((user) => ({ ...user.data(), id: user.id }));
};
export const getUser = async (id, db) => getDoc(doc(db, 'users', id));

export const updateUser = async (id, user, db) => {
  console.log('Updating User', user);
  try {
    await setDoc(doc(db, 'users', id), user);
  } catch (err) {
    console.log(err);
  }
};

export const usersFirebaseDb = (db) => ({
  getUsers: () => getUsers(db),
  getUser: (id) => getUser(id, db),
  updateUser: (id, user) => updateUser(id, user, db),
});
export default {
  usersFirebaseDb,
};
