import { ref } from '@vue/reactivity';
import { onUnmounted } from '@vue/runtime-core';

import {
  collection, getDocs, doc, getDoc, onSnapshot, addDoc, deleteDoc, setDoc,
} from 'firebase/firestore';

export const getTeams = async (db) => {
  const teams = await getDocs(collection(db, 'teams'));
  return teams.docs;
};

export const getTeam = async (id, db) => getDoc(doc(db, 'teams', id));

export const createTeam = async (team, db) => {
  console.log('Creating Team', team);
  try {
    await addDoc(collection(db, 'teams'), team);
  } catch (err) {
    console.log(err);
  }
};

export const updateTeam = async (id, team, db) => {
  console.log('Creating Team', team);
  try {
    await setDoc(doc(db, 'teams', id), team);
  } catch (err) {
    console.log(err);
  }
};

export const deleteTeam = async (id, db) => {
  deleteDoc(doc(db, 'teams', id));
};

export const useLoadTeams = (db) => {
  const teams = ref([]);
  const close = onSnapshot(collection(db, 'teams'), (snapshot) => {
    teams.value = snapshot.docs.map((team) => ({ id: team.id, ...team.data() }));
  });
  onUnmounted(close);
  return teams;
};

export const teamsFirebaseDb = (db) => ({
  getTeams: () => getTeams(db),
  getTeam: (id) => getTeam(id, db),
  createTeam: (team) => createTeam(team, db),
  updateTeam: (id, team) => updateTeam(id, team, db),
  deleteTeam: (id) => deleteTeam(id, db),
  useLoadTeams: () => useLoadTeams(db),
});
export default {
  teamsFirebaseDb,
};
