<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-title>Creat New Team</v-card-title>
        <v-card-text>
          <v-form @submit.prevent="onSubmit">
            <div class="form-groupp mt-3">
              <v-text-field
                id="name"
                label="Name"
                placeholder="Name"
                v-model="form.name"
              ></v-text-field>
            </div>
            <div class="form-group mt-3">
              <v-text-field
                id="league"
                label="League"
                placeholder="League"
                v-model="form.league"
              ></v-text-field>
            </div>
            <v-card-actions>
              <v-btn color="success" type="submit">Create Team</v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col> </v-col>
  </v-row>
</template>

<script>
import { reactive } from 'vue';
import { mapActions } from 'vuex';

export default {
  setup() {
    const form = reactive({ name: '', league: '' });
    return { form };
  },
  methods: {
    ...mapActions(['createTeam']),
    async onSubmit() {
      console.log('onSubmit', this);
      await this.createTeam(this.form);
      this.form.name = '';
      this.form.league = '';
    },
  },
};
</script>
