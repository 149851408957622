<template>
  <v-row>
    <v-col>
      <TeamList />
    </v-col>
  </v-row>
</template>

<script>
import TeamList from './TeamList.vue';

export default {
  components: { TeamList },
};
</script>

<style></style>
