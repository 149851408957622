import {
  getFirestore, connectFirestoreEmulator,
} from 'firebase/firestore';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { getApp } from 'firebase/app';
import { teamsFirebaseDb } from './teams';
import { leaguesFirebaseDb } from './leagues';
import { usersFirebaseDb } from './users';

require('firebase/firestore');

const app = getApp();
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(process.env.VUE_APP_RECAPTCHA),
  isTokenAutoRefreshEnabled: true,
});

let db = getFirestore(appCheck.app);

if (process.env.VUE_APP_FIREBASE_LOCAL) {
  db = getFirestore(app);
  console.log('Using local simulator firestore');
  connectFirestoreEmulator(db, 'localhost', 9097);
}

export const teamsDb = teamsFirebaseDb(db);
export const leaguesDb = leaguesFirebaseDb(db);
export const usersDb = usersFirebaseDb(db);

export default {
  teamsDb,
  leaguesDb,
  usersDb,
};
