<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-title>Edit Team</v-card-title>
        <v-card-text>
          <v-form @submit.prevent="onSubmit">
            <div class="form-groupp mt-3">
              <v-text-field
                id="name"
                label="Name"
                placeholder="Name"
                v-model="form.name"
              ></v-text-field>
            </div>
            <div class="form-group mt-3">
              <v-text-field
                id="league"
                label="League"
                placeholder="League"
                v-model="form.league"
              ></v-text-field>
            </div>
            <v-card-actions>
              <v-btn color="success" type="submit">Edit Team</v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col> </v-col>
  </v-row>
</template>

<script>
import { computed, onMounted, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { teamsDb } from '@/db';

export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    const teamId = computed(() => route.params.id);

    const form = reactive({ name: '', league: '' });
    onMounted(async () => {
      const team = await teamsDb.getTeam(teamId.value);
      const teamData = team.data();
      form.name = teamData.name;
      form.league = teamData.league;
    });

    const onSubmit = async () => {
      await teamsDb.updateTeam(teamId.value, { ...form });
      router.push('/manager/teams');
    };

    return { form, onSubmit };
  },
};
</script>
