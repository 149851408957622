<template>
  <v-card class="mx-auto" tile>
    <v-list>
      <v-list-item>
        <v-list-item-avatar>
          <v-avatar color="indigo">
            <v-icon light> mdi-account-circle </v-icon>
          </v-avatar>
        </v-list-item-avatar>
      </v-list-item>
      <v-list-item link>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            {{ `${user.firstName || 'First'} ${user.LastName || 'Last'}` }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-icon>mdi-menu-down</v-icon>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list color="transparent">
      <v-list-item v-for="item in list" :key="item.name" link :to="item.link">
        <v-list-item-content>
          <v-list-item-title>{{ item.name }} </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ProfileSidebar',
  setup() {
    return {
      list: [{ name: 'Profile', link: '' }],
    };
  },
  computed: {
    ...mapGetters(['user']),
  },
};
</script>
